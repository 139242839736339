import styled from '@emotion/styled'
import mq from 'styles/breakpoints'
import { FaStar } from 'react-icons/fa'

export const FaStarStyled = styled(FaStar)`
  margin-right: 0.5em;
`

export const TestimonySectionContainer = styled.div`
  display: flex;
  height: 100%;
  background: #ffb732;
  padding-right: 15em;
  padding-left: 15em;
  padding-top: 4em;
  padding-bottom: 2em;

  ${mq['lg']} {
    padding-right: 2em;
    padding-left: 2em;
  }

  ${mq['md']} {
    height: 100%;
    flex-direction: column;
    padding-right: 0em;
    padding-left: 0em;
  }

  ${mq['sm']} {
    padding-bottom: 1rem;
  }
`

export const Avatar = styled.div`
  position: relative;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  text-align: center;
  margin-left: 2em;
  padding-bottom: 0.5rem;

  div {
    width: 75%;
  }

  ${mq['xl']} {
    div {
      width: 100%;
    }
  }

  ${mq['lg']} {
    width: 80%;
  }

  ${mq['md']} {
    margin: auto;
    margin-bottom: 6em;
    width: 70%;
  }

  ${mq['sm']} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
`

export const TestimonyBox = styled.div`
  font-size: 3rem;

  ${mq['xxl']} {
    font-size: 1.8rem;
  }
`

export const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  font-size: 2em;
`

export const AvatarImage = styled.img`
  width: 12em;
`
