import React from 'react'
import styled from '@emotion/styled'
import { ScrollDownIndicator } from 'react-landing-page'

const StyledScrollDownIndicator = styled(ScrollDownIndicator)`
  cursor: pointer;
`

const StyledIndicatorWrapper = styled.div`
  text-decoration: none;
  color: white;
`

const StyledIndicator = () => {
  return (
    <StyledIndicatorWrapper>
      <StyledScrollDownIndicator></StyledScrollDownIndicator>
    </StyledIndicatorWrapper>
  )
}

export default StyledIndicator
