import React from 'react'
import { useTranslation } from 'react-i18next'

import fran from 'images/jpg/fran.jpg'
import leonardo from 'images/jpg/leonardo.jpg'
import nuno from 'images/jpg/nuno.jpg'
import {
  FaStarStyled,
  TestimonySectionContainer,
  Avatar,
  AvatarImage,
  TestimonyBox,
  StarsContainer,
} from 'components/sections/TestimonySectionComponents'
import { ShowcaseContainer, ShowcaseTitle } from 'components/HomeComponents'

const TestimonySection = () => {
  const { t } = useTranslation('testimonySection')
  return (
    <ShowcaseContainer>
      <ShowcaseTitle>
        <h1>{t('fastTrack')}</h1>
        <h2>{t('hear')}</h2>
      </ShowcaseTitle>
      <TestimonySectionContainer>
        <Avatar>
          <AvatarImage src={leonardo}></AvatarImage>
          <TestimonyBox>{t('testimony1')}</TestimonyBox>
          <StarsContainer>
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
          </StarsContainer>
        </Avatar>
        <Avatar>
          <AvatarImage src={fran}></AvatarImage>
          <TestimonyBox>{t('testimony2')}</TestimonyBox>
          <StarsContainer>
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
          </StarsContainer>
        </Avatar>
        <Avatar>
          <AvatarImage src={nuno}></AvatarImage>
          <TestimonyBox>{t('testimony3')}</TestimonyBox>
          <StarsContainer>
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
            <FaStarStyled />
          </StarsContainer>
        </Avatar>
      </TestimonySectionContainer>
    </ShowcaseContainer>
  )
}

export default TestimonySection
