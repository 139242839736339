import styled from '@emotion/styled'
import mq from 'styles/breakpoints'
import { FaStar } from 'react-icons/fa'

import italianGirl from 'images/jpg/digital-go-italian-girl.jpg'
import PeopleBanner from 'images/jpg/people-banner.jpg'

export const LandingSection = styled.div`
  display: flex;
  height: 100vh;
  background-image: url(${italianGirl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  font-size: 5rem;

  ${mq['xxl']} {
    font-size: 3rem;
  }

  ${mq['md']} {
    font-size: 2em;
    justify-content: center;
    background-position: 80% 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #ffffff;
  margin-left: 4em;
  font-size: 4.5rem;
  width: 20em;
  height: 100%;
  margin-top: 10%;

  ${mq['xl']} {
    height: 100%;
    margin-top: 30%;
  }

  ${mq['md']} {
    font-size: 3.5rem;
    align-items: center;
    justify-content: center;
  }

  ${mq['sm']} {
    font-size: 1.8rem;
    margin-left: 0;
    text-align: center;
  }

  ${mq['xsm']} {
    font-size: 1.6rem;
  }
`

export const Button = styled.button`
  background: #0099ff;
  font-size: 1em;
  align-self: flex-start;
  margin-top: 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  color: #ffffff;

  ${mq['sm']} {
    align-self: center;
  }
`

export const ShowcaseLeftContainer = styled.div`
  width: 35%;
  padding-top: 15rem;
  padding-right: 2rem;
  color: #213054;

  h1 {
    font-size: 6rem;
    font-weight: 700;
    text-align: left;
    width: 100%;
  }

  p {
    font-size: 3.5rem;
    padding: 0.5em 0.5em;
  }

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
  }

  ${mq['xxl']} {
    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.5rem;
    }
  }

  ${mq['md']} {
    width: 100%;
    padding-top: 5rem;

    h1 {
      font-size: 2rem;
      text-align: center;
    }

    p {
      font-size: 1.5rem;
    }
  }
`

export const FeaturesImage = styled.img`
  width: 60%;
  margin-top: 2em;
`

export const WhiteTriangle = styled.img`
  margin-top: -1.2em;
  width: 8em;
`

export const CallToActionImageContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  align-items: center;
  background-image: url(${PeopleBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 20%;
  width: 100%;
  height: 80rem;

  ${mq['md']} {
    height: 40rem;
    background-size: cover;
  }

  ${mq['xxl']} {
    h1 {
      font-size: 5rem;
    }

    h4 {
      font-size: 2.5rem;
    }
  }

  ${mq['sm']} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const SubscriptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  ${mq['xxl']} {
    justify-content: space-evenly;
  }

  ${mq['md']} {
    margin-top: 0rem;
  }
`

export const FaStarStyled = styled(FaStar)`
  margin-right: 0.5em;
`

export const StyledIndicatorWrapper = styled.div``
