import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import mq from 'styles/breakpoints'
import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import logo from 'images/png/fluentworlds_banner.png'
import UserAuth from 'components/UserAuth'
import UserAuthNavLinks from 'components/UserAuthNavLinks'
import {
  NavbarContainer,
  NavCenter,
  NavHeaderDigitalGo,
  NavLinksWrapper,
  LogoButton,
  BurgerIcon,
  LoadingIndicatorSignIn,
  LoadingIndicatorSignUp,
} from 'components/HeaderComponents'
import { USER_AUTHENTICATED_QUERY_PARAM } from 'utils/digitalGoAnalytics'

const DIGITAL_GO_ACCOUNT_PATH = '/digital-go/account/'
const DIGITAL_GO_HOME_PATH = '/digital-go/'
const DIGITAL_GO_REDEEM_PATH = '/digital-go/redeem/'

const Header = () => {
  const [isOpen, setNav] = useState(false)
  const { user, auth0Loading, isAuthenticated } = useAuth0()
  const { username, fetchUserFromFirestore, userStateLoading } = useContext(
    UserContext,
  )
  const { t, ready } = useTranslation('header')

  // when user is authenticated, fetch the user from DB
  useEffect(() => {
    if (user && username === '') {
      fetchUserFromFirestore()
    }
    // eslint-disable-next-line
  }, [user])

  const toggleNav = () => {
    setNav(!isOpen)
  }

  const NavLinks = styled.div`
    display: flex;
    justify-content: center;
    width: 80%;
    background-color: transparent;
    list-style-type: none;
    transition: all 0.3s linear;
    font-size: 2.5rem;
    text-decoration: none;
    text-align: center;
    height: 0;

    a {
      color: #213054;
      display: block;
      padding: 1rem 1.25rem;
      text-decoration: none;
      text-transform: capitalize;
      transition: all 0.3s linear;
      font-weight: bold;
      text-decoration: none;

      ${mq['xxl']} {
        max-width: unset;
        font-size: 2rem;
        text-transform: capitalize;
      }
    }

    ${mq['xl']} {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-right: 13rem;
    }

    ${mq['lg']} {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      overflow: hidden;
    }

    @media screen and (min-width: 992px) {
      height: auto;
      display: flex;
      list-style-type: none;
    }

    ${isOpen ? 'height: 15rem' : ''}
  `

  return (
    <NavbarContainer>
      <NavCenter>
        <NavHeaderDigitalGo>
          <Link to={DIGITAL_GO_HOME_PATH}>
            <img src={logo} alt="fluentworlds.com" />
          </Link>
          <LogoButton onClick={toggleNav}>
            <BurgerIcon />
          </LogoButton>
        </NavHeaderDigitalGo>
        <NavLinksWrapper>
          <NavLinks>
            <li>
              <Link
                to={DIGITAL_GO_HOME_PATH}
                activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
              >
                {t('home')}
              </Link>
            </li>
            {!isAuthenticated ? null : (
              <li>
                <Link
                  to={DIGITAL_GO_REDEEM_PATH}
                  activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
                >
                  {t('redeem')}
                </Link>
              </li>
            )}

            {auth0Loading || !ready ? (
              LoadingIndicatorSignIn
            ) : (
              <UserAuthNavLinks
                signInPath={DIGITAL_GO_ACCOUNT_PATH}
                accountPath={DIGITAL_GO_ACCOUNT_PATH}
                digitalGoQueryParam={USER_AUTHENTICATED_QUERY_PARAM}
              />
            )}
          </NavLinks>

          {auth0Loading || userStateLoading || !ready ? (
            LoadingIndicatorSignUp
          ) : (
            <UserAuth
              signInPath={DIGITAL_GO_ACCOUNT_PATH}
              logoutReturnPath={DIGITAL_GO_HOME_PATH}
              digitalGoQueryParam={USER_AUTHENTICATED_QUERY_PARAM}
            />
          )}
        </NavLinksWrapper>
      </NavCenter>
    </NavbarContainer>
  )
}

export default Header
