import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet'
import { Element, Link as ScrollLink } from 'react-scroll'
import { Global } from '@emotion/react'

import { useAuth0 } from 'utils/react-auth0-spa'
import GlobalStyles from 'styles/GlobalStyles'
import StyledIndicator from 'components/common/StyledIndicator'
import fwFeatures from 'images/jpg/fwfeatures-en.jpg'
import fwShowcaseDevices from 'images/png/fw-showcase2devices.png'
import whiteTriangle from 'images/png/white-triangle.png'
import FWAppIcon from 'images/png/products-fw-app-logo.png'
import GooglePlayIcon from 'images/png/google_play.png'
import AppleIcon from 'images/png/apple.png'
import DigitalGoHeader from 'components/digital-go/DigitalGoHeader'
import Footer from 'components/Footer'
import TestimonySection from 'components/sections/TestimonySection'
import {
  LandingSection,
  FlexContainer,
  FaStarStyled,
  Button,
  ShowcaseLeftContainer,
  CallToActionImageContainer,
  WhiteTriangle,
  FeaturesImage,
  StyledIndicatorWrapper,
} from 'components/digital-go/DigitalGoHomeComponents'
import {
  ShowcaseContainer,
  ShowcaseTitle,
  FlexCenter,
  ShowcaseImageNew,
} from 'components/HomeComponents'
import {
  HeaderTitle,
  CallToActionSection,
  SubscriptionsContainer,
  Subscription,
  SubscriptionRow,
  SubscriptionColumnOne,
  SubscriptionColumnTwo,
  AppLinksContainer,
  StoreIconContainer,
  SmallIcon,
  AppStoreText,
} from 'components/ProductComponents'
import {
  trackRegistrationEvent,
  trackClickEvent,
} from 'utils/digitalGoAnalytics'
import {
  FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK,
  FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK,
} from 'constants/product-links'

const Index = () => {
  const DIGITAL_GO_REDEEM_PATH = '/digital-go/redeem/'
  const DIGITAL_GO_REDIRECT_TO_ACCOUNT_FLAG = '?account=true'

  const { user } = useAuth0()
  const { t } = useTranslation('digitalGoIndex')
  const [userAuthenticatedFlag] = useQueryParam('user-auth', StringParam)

  useEffect(() => {
    if (
      userAuthenticatedFlag !== undefined &&
      userAuthenticatedFlag &&
      userAuthenticatedFlag === 'true' &&
      user
    ) {
      trackRegistrationEvent(user.sub)
    }
  }, [user, userAuthenticatedFlag])

  return (
    <>
      <Global styles={GlobalStyles} />
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <DigitalGoHeader />
      <LandingSection>
        <FlexContainer>
          <h1>{t('header1')}</h1>
          <Button
            onClick={() => {
              trackClickEvent('indexAccedi', '')
              navigate(
                DIGITAL_GO_REDEEM_PATH + DIGITAL_GO_REDIRECT_TO_ACCOUNT_FLAG,
              )
            }}
          >
            {t('button')}
          </Button>
          <StyledIndicatorWrapper>
            <ScrollLink
              activeClass="active"
              to="showcase"
              spy={true}
              smooth={true}
              duration={600}
            >
              <StyledIndicator>{t('learnMore')}</StyledIndicator>
            </ScrollLink>
          </StyledIndicatorWrapper>
        </FlexContainer>
      </LandingSection>
      <Element name={'showcase'}>
        <ShowcaseContainer style={{ backgroundColor: 'white' }}>
          <ShowcaseTitle name="fluentworlds">
            <h1>{t('theWorldIsNotFlat')}</h1>
            <h2>{t('languageLearning')}</h2>
          </ShowcaseTitle>
          <FlexCenter>
            <ShowcaseImageNew>
              <img src={fwShowcaseDevices} alt="Fluent Worlds" />
            </ShowcaseImageNew>
            <ShowcaseLeftContainer>
              <h1>{t('whyFluentWorlds.header')}</h1>
              <ul>
                <li>
                  <FaStarStyled />
                  <p>{t('whyFluentWorlds.p1')}</p>
                </li>
                <li>
                  <FaStarStyled />
                  <p>{t('whyFluentWorlds.p2')}</p>
                </li>
                <li>
                  <FaStarStyled />
                  <p>{t('whyFluentWorlds.p3')}</p>
                </li>
                <li>
                  <FaStarStyled />
                  <p>{t('whyFluentWorlds.p4')}</p>
                </li>
                <li>
                  <FaStarStyled />
                  <p>{t('whyFluentWorlds.p5')}</p>
                </li>
              </ul>
            </ShowcaseLeftContainer>
          </FlexCenter>
        </ShowcaseContainer>
      </Element>
      <TestimonySection />
      <ShowcaseTitle style={{ width: '100%' }}>
        <h1>{t('features')}</h1>
      </ShowcaseTitle>
      <ShowcaseContainer style={{ background: '#000000' }}>
        <WhiteTriangle src={whiteTriangle} />
        <FeaturesImage src={fwFeatures} />
      </ShowcaseContainer>
      <CallToActionSection>
        <HeaderTitle>
          <h1>{t('startUsingFluentWorlds')}</h1>
        </HeaderTitle>
        <CallToActionImageContainer>
          <SubscriptionsContainer>
            <Subscription style={{ paddingBottom: '3em' }}>
              <SubscriptionRow>
                <SubscriptionColumnOne>
                  <img src={FWAppIcon} alt="FluentWorlds App" />
                </SubscriptionColumnOne>
                <SubscriptionColumnTwo>
                  <h3>FluentWorlds</h3>
                  <p>{t('immersive')}</p>
                </SubscriptionColumnTwo>
              </SubscriptionRow>
              <AppLinksContainer>
                <h5>{t('downloadOn')}</h5>
                <StoreIconContainer>
                  <a
                    href={FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SmallIcon src={GooglePlayIcon} alt="google play" />
                  </a>
                  <AppStoreText>
                    <a
                      href={FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Play
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
                <StoreIconContainer>
                  <a
                    href={FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SmallIcon src={AppleIcon} alt="apple store" />
                  </a>

                  <AppStoreText>
                    <a
                      href={FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App Store
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
              </AppLinksContainer>
            </Subscription>
          </SubscriptionsContainer>
        </CallToActionImageContainer>
      </CallToActionSection>
      <Footer />
    </>
  )
}

export default Index
