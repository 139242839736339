import { isProductionEnvironment } from 'utils/utils'

const DIGITAL_GO_GTM_TAG = 'AW-1006346032'
const DIGITAL_GO_GA_REGISTRATION_TAG = 'na4DCN_Wzo0CELC-7t8D'
const DIGITAL_GO_GA_PAYMENT_TAG = 'mVfpCMHWzo0CELC-7t8D'

export const USER_AUTHENTICATED_QUERY_PARAM = '?user-auth=true'

export const trackRegistrationEvent = (customerId) => {
  if (typeof window !== 'undefined' && isProductionEnvironment()) {
    window.gtag('event', 'conversion', {
      send_to: `${DIGITAL_GO_GTM_TAG}/${DIGITAL_GO_GA_REGISTRATION_TAG}`,
      ...{ customer_id: customerId },
    })
  }
}

export const trackPaymentSuccessEvent = (transactionId) => {
  if (typeof window !== 'undefined' && isProductionEnvironment()) {
    window.gtag('event', 'conversion', {
      send_to: `${DIGITAL_GO_GTM_TAG}/${DIGITAL_GO_GA_PAYMENT_TAG}`,
      ...{ transaction_id: transactionId },
    })
  }
}

export const trackClickEvent = (category, label) => {
  if (typeof window !== 'undefined' && isProductionEnvironment()) {
    window.gtag('event', 'click', {
      event_category: category,
      event_label: label,
    })
  }
}
